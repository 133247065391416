@charset 'utf-8';

@import 'settings';
@import 'customsettings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

//LazyStack

.lets-go {
    background-image: linear-gradient(#181525,#443d6e);
    //background-image: url('/assets/img/pic17.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-top: #d5dbdb;
    border-top-width: 2px;
    border-top-style: solid;
    border-bottom: #d5dbdb;
    border-bottom-width: 2px;
    border-bottom-style: solid;    
    height: 55px;
}

.cards {
   margin-top: 20px;
   margin-bottom: 20px;
   /*
   background-image: url('/assets/img/pic1.jpg');
   background-size: center;
   background-repeat: no-repeat;
    */
}

.card{
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: space-between;

    border:4px solid #d5dbdb;
    border-radius:13px;
    border-top-right-radius: 60px;
    
    background-color: #3b103bda;
    background-image: url('/assets/img/pic16.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    margin: 20px;
    height: 400px;
    max-width: 500px;


}

.card-header {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0px;
    margin-bottom: 8px;;
}

.card-title {
    font-size: 25px;
    max-height: 60px;
}

.card-text {
    font-size: 20px;
}

.card-blurb {
    font-size: 17px;
    padding: 10px;
}

.card-image {
    flex-grow: 1 1 auto;
    padding: 0px;
    margin: 0px;
}

.card-image img {
    height: 100%;
    margin: 0px;
    padding: 0px;
}


#outer{
    border: .125rem solid;
    border-radius: 13px;
    background-color: rgba(0, 0, 0, .71);
    padding-top: 0.5vw;
    padding-right: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 0.5vw;
    max-width: 405px;
    width: 28vw;
}

#inner{
    font-size: 3vw;
    // font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
    color: rgba(180, 23, 186, 1);
    @include breakpoint(large up) {
        font-size: 38px;
    }
}
    
#logo-image {
    height: 7vw; 
    max-height: 100px;
}


.vista {
    background-image: url('/assets/img/pic12.jpg');
    background-size: cover;
    max-height: 250px;
    height: 20vw;
    padding-top: 6.5%;
}

.slide-left {
    @include mui-slide(out, $fade: true, $direction: left, $duration: 250ms);
}

.slide-right {
    @include mui-slide(out, $fade: true, $direction: right, $duration: 250ms);
}

.introquote {

    color: #d5dbdb; 
    font-size: 20px; 
    background-color: #3b103bda; 
    border-radius: 20px;
    padding: 10px; 
    margin: 10px;

}

.manual-list ul {
    list-style-type:none;
}

.menu a{
    color: #d5dbdb;
}

.lz-r-content{
    background-color: #221C1C;
    padding-right: 10px;
    padding-left:10px;
}

.fill-1{
    background-image: linear-gradient(#181525,#443d6e)
}

